import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css';
import './assets/css/respornsive.css';
import './assets/css/radioandcheckbox.css';

import About from './pages/about';

function App() {
  return (
    <div className="App">
    <BrowserRouter basename="">

      {/* <ScrollTotop /> */}

      <Routes>
        {/* <Navigation> */}

        <Route exact path="/" element={<About />} />
        {/* <Route path="/logout" element={<Logout />} /> 
        <Route path="/memberdetail" element={<AuthMiddleware><Member /></AuthMiddleware>} />
        <Route path="/drive/folder/:id" element={<AuthMiddleware><Systemdrive /></AuthMiddleware>} />*/}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
