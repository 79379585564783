import React, { useEffect } from 'react';

import WOW from 'wowjs';

import bg from '../assets/img/bg.png';
import bg2 from '../assets/img/bg2.png';
import item from '../assets/img/item.png';
import item2 from '../assets/img/item2.png';
import bt from '../assets/img/icon/Btop.svg';

import web from '../assets/img/icon/Web.svg';
import ig from '../assets/img/icon/IG.svg';
import email from '../assets/img/icon/Mail.svg';
import fb from '../assets/img/icon/FB.svg';
import yt from '../assets/img/icon/YT.svg';
import gt from '../assets/img/icon/go_to_link.svg';

const About = () => {

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, [])

    const scrollToTop = (e) => {
        // new WOW.WOW({
        //     live: false
        // }).init();
        // window.scrollTo(0, 0)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }
    return (
        <>

            <div className="container-fluid" style={{ zIndex: "1", backgroundColor: "#fff" }}>
                <div className="row justify-content-center">
                    <div className='col-12 col-md-6 col-lg-3 bg-no' style={{ backgroundImage: `url(${bg})` }}>
                        <div className='position-relative text-start' style={{ minHeight: '100vh' }}>
                            <div className='text-end pe-2' style={{ marginTop: "-58px" }}>
                                <img src={item} className="" />
                            </div>
                            <div className='mt-5 wow fadeIn' data-wow-duration="1.7s">
                                <div className='text-topic ' style={{ lineHeight: '1.2' }}>About <br />SEAMEO SEPS</div>
                                <div className='font-weight-400 mt-3'>
                                    The SEAMEO Regional Centre for Sufficiency Economy Philosophy for Sustainability (SEAMEO SEPS) was established in 2018 as an excellence centre in Sufficiency Economy Philosophy (SEP). The Sufficiency Economy Philosophy (SEP) is a fundamental principle developed by His Majesty the Late King Bhumibol Adulyadej in the 1970s based on the foundational traits of moderation, reasonableness, and prudence, adding knowledge and virtue as guidelines for living. This philosophy is an approach to sustainable development that promotes utilizing knowledge and virtue to create a sense of moderation, reasonableness, and prudence. It stresses the middle path as the overriding principle for appropriate conduct and way of life for the entire populace.
                                    <br />
                                    <br />
                                    SEP thinking has supporters across Asia and on other continents. The SEP principle is, thus recognized by many global players such as international institutions, NGOs, businesses, and especially rural local communities across the world.
                                    <br />
                                    <br />
                                    SEAMEO SEPS wishes to inspire member countries to consider implementing following the SEP for SDGs. It can help introduce a new mindset and new education platform to member states in the hope that this principle would be able to serve as key components of the national, regional, and international development education for SDGs.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className='col-12 col-md-6 col-lg-3 p-0'>
                        <div className='position-relative overflow-hidden'>
                            <img src={bg2} className="w-100" />
                            <div className='position-absolute wow fadeIn' data-wow-delay="2s" style={{ top: "-40px", right: "-30px" }}>
                                <img src={item2} className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className='col-12 col-md-6 col-lg-3 px-3' style={{ marginTop: "-50px", zIndex: "9" }}>
                        <div className="row align-items-center text-start">
                            <div className='col-6 col-md-6 col-lg-6 pe-2'>
                                <a href='https://www.seameoseps.org/' target="_blank" className='box-social'>
                                    <div className='mb-3 text-end'><img src={gt} /></div>
                                    <div className='mb-2'><img src={web} /></div>
                                    <div className='font-weight-700 font-size-24'>Website</div>
                                    <div className='font-weight-600 font-size-10 mb-2'>www.seameoseps.org</div>
                                </a>
                                <a href='https://www.instagram.com/seameoseps/?igshid=YmMyMTA2M2Y%3D' target="_blank" className='box-social'>
                                    <div className='mb-3 text-end'><img src={gt} /></div>
                                    <div className='mb-2'><img src={ig} /></div>
                                    <div className='font-weight-700 font-size-24'>Instagram</div>
                                    <div className='font-weight-600 font-size-10 mb-2'>seameoseps</div>
                                </a>
                                <a href="mailto:info@seameoseps.org" target="_blank" className='box-social'>
                                    <div className='mb-3 text-end'><img src={gt} /></div>
                                    <div className='mb-2'><img src={email} /></div>
                                    <div className='font-weight-700 font-size-24'>Email</div>
                                    <div className='font-weight-600 font-size-10 mb-2'>info@seameoseps.org</div>
                                </a>
                            </div>
                            <div className='col-6 col-md-6 col-lg-6 ps-2'>
                                <a href='https://www.facebook.com/seameoseps' target="_blank" className='box-social'>
                                    <div className='mb-3 text-end'><img src={gt} /></div>
                                    <div className='mb-2'><img src={fb} /></div>
                                    <div className='font-weight-700 font-size-24'>Facebook</div>
                                    <div className='font-weight-600 font-size-10 mb-2'>seameoseps</div>
                                </a>
                                <a href='https://www.youtube.com/@seameoseps9118' target="_blank" className='box-social'>
                                    <div className='mb-3 text-end'><img src={gt} /></div>
                                    <div className='mb-2'><img src={yt} /></div>
                                    <div className='font-weight-700 font-size-24'>Youtube</div>
                                    <div className='font-weight-600 font-size-10 mb-2'>SEAMEO SEPS</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center pt-4">
                    <div className='col-12 col-md-6 col-lg-3 text-end'>
                        <div className='font-weight-500' onClick={(e) => scrollToTop(e)}>Back to top
                            <img src={bt} className="pt-3" />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default About;